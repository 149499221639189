import React from 'react'
import logo4 from "../assets/images/logo4.png";
import logo5 from "../assets/images/logo6.png";
import anim1 from "../assets/images/anim1.png"
import anim3 from "../assets/images/anim3.png"
import { useNavigate } from 'react-router-dom';

function FooterTwo() {
    const navigate = useNavigate();

    const navigateToSection = (sectionId) => {
        navigate('/products', { state: { sectionId } });
      };
    return (
        <div style={{
            backgroundImage: "linear-gradient(360deg, #A4D7FF  -20.27%, rgba(164, 215, 255, 0) 83.94%)"
        }} className="relative mt-16 bg-primary ">

            <svg
                className="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-deep-purple-accent-400"
                preserveAspectRatio="none"
                viewBox="0 0 1440 54"
            >
                <path
                    fill="#99bee69e"
                    className=''
                    d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
                />
            </svg>
            <div className="px-4 pt-12   md:px-24 lg:px-10 relative">
                <img src={anim1} className='absolute bottom-24  opacity-40   ' />
                <img src={anim3} className='absolute  right-0 ' />
                <div className="grid gap-16  row-gap-10 mb-8 lg:grid-cols-6">
                    <div className="md:max-w-md lg:col-span-2">
                        <a
                            href="/"
                            aria-label="Go home"
                            title="Company"
                            className="inline-flex items-center"
                        >

                            <img src={logo5} className=' w-44 ' />

                            <span className="ml-2 text-xl font-bold tracking-wide text-gray-100 uppercase">
                                Company
                            </span>
                        </a>
                        <div className="mt-4 lg:max-w-sm">
                            <p className="text-sm text-deep-purple-50">
                                At Gigantech, we blend innovation and expertise in AI and software development to empower businesses. Partner with us to transform your ideas into reality and redefine what's possible in technology!


                            </p>
                            {/* <p className="mt-4 text-sm text-deep-purple-50">
                                Eaque ipsa quae ab illo inventore veritatis et quasi architecto
                                beatae vitae dicta sunt explicabo.
                            </p> */}
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2  gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
                        <div>
                            <p className="font-semibold tracking-wide text-teal-accent-400">
                                Industries
                            </p>
                            <ul className="mt-2 space-y-2">
                                <li>
                                    <a
                                        href="/industries/ecommerce"
                                        className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                                    >
                                        Ecommerce
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/industries/education"
                                        className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                                    >
                                        Education
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/industries/semiconductors"
                                        className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                                    >
                                        Semiconductors

                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/industries/iot"
                                        className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                                    >
                                        IOT
                                    </a>
                                </li>

                                <li>
                                    <a
                                        href="/industries/healthcarelifestyle"
                                        className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                                    >
                                        Healthcare and Lifestyle
                                    </a>
                                </li>


                            </ul>
                        </div>
                        <div>
                            <p className="font-semibold tracking-wide text-teal-accent-400">
                                Industries            </p>
                            <ul className="mt-2 space-y-2">
                                <li>
                                    <a
                                        href="/industries/rail"
                                        className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                                    >
                                        Rail
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/industries/ai"
                                        className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                                    >
                                        Artificial Intellegence
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/industries/hardwareengineering"
                                        className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                                    >
                                        Hardware  Engineering
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/industries/electricutility"
                                        className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                                    >
                                        Electric Utility
                                    </a>
                                </li>

                            </ul>
                        </div>
                        <div>
                            <p className="font-semibold tracking-wide text-teal-accent-400">
                                AI Products

                            </p>
                            <ul className="mt-2 space-y-2">
                                <li>
                                    <a
                                        onClick={() => navigateToSection('section1')}
                                        className=" cursor-pointer transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                                    >
                                        Chat With Docs
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => navigateToSection('section2')}
                                        className=" cursor-pointer transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                                    >
                                        Outlook Email Chat
                                    </a>
                                </li>
                                <li>
                                    <a
                                      onClick={() => navigateToSection('section3')}
                                        className=" cursor-pointer transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                                    >
                                        Database Agent

                                    </a>
                                </li>
                                <li>
                                    <a
                                         onClick={() => navigateToSection('section4')}
                                        className=" cursor-pointer transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                                    >
                                        TeachAssist
                                    </a>
                                </li>

                            </ul>
                        </div>
                        <div>
                            <p className="font-semibold tracking-wide text-teal-accent-400 ">
                                Contact Us

                            </p>
                            <ul className="mt-2 space-y-2">

                                <li>
                                    <a
                                      
                                        className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                                    >
                                        contact@gigantech.io
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center pt-5 pb-5 border-t border-[#f5822082] sm:flex-row">
                    <p className="text-base ">
                        © Copyright 2024 GiganTech. All rights reserved.
                    </p>
                    {/* <div className="flex items-center mt-4 space-x-4 sm:mt-0">
                        <a
                            href="/"
                            className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
                        >
                            <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                                <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
                            </svg>
                        </a>
                        <a
                            href="/"
                            className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
                        >
                            <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                                <circle cx="15" cy="15" r="4" />
                                <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                            </svg>
                        </a>
                        <a
                            href="/"
                            className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
                        >
                            <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                                <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                            </svg>
                        </a>
                    </div> */}
                </div>
            </div>
        </div >
    )
}

export default FooterTwo