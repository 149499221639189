import React, { useEffect } from "react";
import chatwithdocs from "../assets/images/chatwithdocs.png";
import databaseagent from "../assets/images/databaseagent.png";
import outlookemail from "../assets/images/outlookemail.png";
import teacherassistent from "../assets/images/teacherassistent.png";
import curvedline from "../assets/images/curvedline.png"
import { useLocation, useNavigate } from "react-router-dom";


const sections = [
    {
      id: "section1",
      title: "ChatWithDocs",
      description:
        "Introducing a world-class AI tool leveraging advanced technology to enhance the reading experience, enabling document uploads and providing key insights through simple questions, all while allowing direct interaction with the documents.",
      points: [
        { label: "Universal Compatibility:", text: "Interact with a wide range of formats, including XLSX, PDF, EPUB, DOCX, and beyond." },
        { label: "Seamlessly Intuitive Interface:", text: "A user-friendly design." },
        { label: "Cross-Document Intelligence:", text: "Perform smart searches across multiple documents at once, finding exactly what you need." },
        { label: "Summarization:", text: "Instantly distill complex documents into concise insights, saving you valuable time." },
      ],
      image: chatwithdocs,
    },
    {
      id: "section2",
      title: "Outlook Email Chat",
      description:
        "We’ve reimagined your email experience. Introducing our AI tool, seamlessly integrated with Outlook to elevate your productivity—summarizing, drafting, and replying to emails with unmatched efficiency. Effortless communication, reinvented.",
      points: [
        { label: "Email Summarization:", text: "Instantly distill lengthy emails into concise summaries." },
        { label: "Drafting:", text: "Craft the perfect message in moments with AI-powered drafting." },
        { label: "Replying:", text: "Intelligent replies, tailored to keep your conversations flowing effortlessly." },
      ],
      image: outlookemail,
    },
    {
      id: "section3",
      title: "Database Agent",
      description:
        "Imagine a world where you can converse directly with your database—the ultimate knowledge bearer. No complex queries, just natural conversation, and instant insights at your fingertips. Our database agent lets you converse with your data by converting your questions into executable queries.",
      points: [],
      image: databaseagent,
    },
    {
      id: "section4",
      title: "TeachAssist",
      description:
        "Introducing a groundbreaking AI tool that transforms the learning experience, making it easier and faster for students to grasp complex concepts. With TeachAssist, students can tackle mathematical problems with detailed explanations, streamline their learning process, and achieve more with less effort.",
      points: [
        { label: "Effortless Clarity:", text: "Relieves the academic load by delivering in-depth, step-by-step explanations for mathematical problems." },
        { label: "Insightful Guidance:", text: "Detects errors in student solutions and offers constructive suggestions to refine understanding and improve accuracy." },
      ],
      image: teacherassistent,
    },
  ];

function AiProducts() {
    const navigate=useNavigate()
    let index = 1;

    const location = useLocation();
    const handleClick = () => {
        window.location.href = "/contact"
    }

    useEffect(() => {
        if (location.state?.sectionId) {
            // Use a timeout to ensure scrolling happens after rendering the component
            setTimeout(() => {
                const section = document.getElementById(location.state.sectionId);
                if (section) {
                    section.scrollIntoView({ behavior: 'smooth', });
                }
            }, 100); // Adjust delay if necessary
        }
    }, [location]);

    return (
        <div style={{ position: "relative" }} className="what-we-offer-desc mt-32  px-5    max-w-screen-2xl m-auto">
         {sections.map((section, index) => (
        <div
          key={section.id}
          id={section.id}
          className="desc-images-wrapper mt-10"
          style={{
            flexDirection: index % 2 === 0 ? "row-reverse" : "row",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            zIndex: "1",
          }}
        >
          <div>
            <img
              style={{
                zIndex: "999",
                width: "100%",
                objectFit: "contain",
              }}
              className="services-images"
              src={section.image}
              alt={section.title}
            />
            <button onClick={() => navigate("/tutorials")} className="submit-btn text-white mt-5 text-center py-2 px-3 bg-secondary rounded-xl font-semibold">
              Go to Tutorials
            </button>
          </div>

          <div
            style={{
              maxWidth: "800px",
              textAlign: index % 2 === 0 ? "left" : "rigt",
            }}
          >
            <div className="mb-5 relative">
              <h4 className="font-semibold text-3xl mb-5">{section.title}</h4>
              <img className="absolute w-20 top-10" src={curvedline} alt="Curved Line" />

              <p className="text-[18px] text-[#54595F] mb-4">{section.description}</p>
              {section.points.map((point, i) => (
                <p key={i} className="text-[18px] text-[#54595F] mb-4">
                  <span className="font-semibold">{point.label} </span>
                  {point.text}
                </p>
              ))}
            </div>

            <div onClick={handleClick} className="contact-demo rounded-2xl cursor-pointer">
              <span className="font-semibold italic">
                Contact us for a <span className="text-secondary">Demo</span>
              </span>
            </div>
          </div>
        </div>
      ))}
        </div>


    );
}

export default AiProducts;
