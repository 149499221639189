import React, { useState } from 'react'
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import toast, { Toaster } from 'react-hot-toast';

function ContactUs() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const notify = () => toast.success('Message sent successfully!');

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            const docRef = await addDoc(collection(db, 'contacts'), {
                name,
                email,
                message,
                createdAt: new Date(),
            });

            notify()
        }

        catch (error) {
            console.error("Error adding document: ", error);
        }
        // Clear the form
        setName('');
        setEmail('');
        setMessage('');
    }

    return (
        <div>
            <section className='mt-10' id="contact">
                <div style={{
                    backgroundImage: "linear-gradient(360deg, #A4D7FF  -20.27%, rgba(164, 215, 255, 0) 83.94%)"
                }} className="relative mt-16 contact-box bg-black ">
                    <div class="contact-links">
                        <h2>Get in <span className=' text-secondary'>Touch</span>
                        </h2>
                        <p className='text-center'>We’d love to hear from you! Whether you have a question, feedback, or just want to say hello, please fill out the form below and we’ll get back to you as soon as possible.

                        </p>

                    </div>
                    <div class="contact-form-wrapper">
                        <form onSubmit={handleSubmit}>
                            <div class="form-item">
                                <input onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    placeholder='Name' type="text" name="name" required />
                            </div>
                            <div class="form-item">
                                <input value={email}
                                    onChange={(e) => setEmail(e.target.value)} placeholder='Email' type="text" name="email" required />
                            </div>
                            <div class="form-item">
                                <textarea value={message}
                                    onChange={(e) => setMessage(e.target.value)} rows={4} placeholder='Message' class="" name="message" required></textarea>
                            </div>
                            <button type='submit' class="submit-btn">Send</button>
                        </form>
                    </div>
                </div>
                <Toaster />

            </section>
        </div>
    )
}

export default ContactUs