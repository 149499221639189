
import React, { useState } from "react";

import chatwithdocs from "../assets/images/chatwithdocs.png";
import chatdocsVideo from "../assets/images/chatdocsVideo.mp4";
import { FaBookOpenReader } from "react-icons/fa6";
function Tutorials() {
  const [selectedCategory, setSelectedCategory] = useState("all");

  const [showMore, setShowMore] = useState(null)


  const toggleShowMore = (index) => {
    setShowMore(prevIndex => prevIndex === index ? null : index);
  }

  const data = [
    {
      id: 1,
      category: "Chat With Docs",
      image: chatwithdocs,
      title: "Artificial Intelligence",
      longDesc: [
        {
          heading: "Getting Started",
          subHeadings: [
            {
              subHeading: "1. Upload Your Documents",
              description:
                "You can upload a variety of documents, including books, research papers, legal papers, academic journals, textbooks, instruction manuals, and more.",
            },
            {
              subHeading: "2. Choose How to Chat",
              description:
                "To interact with a single document, select that document. For chatting with all documents in your Dashboard, choose 'Select All'.",
            },
            {
              subHeading: "3. Ask Questions in Your Preferred Language",
              description:
                "Chat with documents is designed to be your personal assistant, offering explanations, summaries, quotes, citations, study questions, and more, all based on your documents.",
            },
            {
              subHeading: "4. Receive Instant Answers",
              description:
                "Chat with documents will quickly extract the information you need from your documents and provide answers or generate content, complete with citations.",
            },
          ],
        },
        {
          heading: "Prompts",
          subHeadings: [
            {
              subHeading: "",
              description:
                "- Limit each chat message to one question or prompt. Chat with documents processes one message at a time, so ensure each contains all necessary details.",
            },
            {
              subHeading: "",
              description: "- Be as specific as possible with your questions.",
            },
            {
              subHeading: "",
              description:
                "- Adjust the number of references based on your needs: increase for summaries, decrease for specific answers.",
            },
            {
              subHeading: "",
              description:
                "- Define the page range to exclude or include parts of the document in your answers.",
            },
            {
              subHeading: "",
              description:
                "If Chat with documents doesn’t provide an answer, try adjusting the number of references.",
            },
          ],
        },


        {
          heading: "Writing Prompts Checklist",
          subHeadings: [
            {
              subHeading: "1. Clarity: Be Specific",
              description:
                "Avoid vague language to get more precise responses. Broad prompts result in general answers, while specific prompts yield detailed information.",
            },
            {
              subHeading: "Vague prompt:",
              description:
                "Can you help me understand how to drive",
            },
            {
              subHeading: "Improved prompt",
              description:
                "What are the first three steps to drive a car?.",
            },
            {
              subHeading: "Vague prompt:",
              description:
                "What are some good spots in Toronto?.",
            },
            {
              subHeading: "2. Simplicity:",
              description:
                " Use Clear Language. Keep your language simple and direct. Break down complex questions into several straightforward prompts.",
            },

            {
              subHeading: "Main question",
              description:
                "Describe the process of yougurt-making in detail",
            },


            {
              subHeading: "Sub-questions:",
              description:
                "What are the steps in yougurt-making?, How does fermentation work?, What factors influence the flavor of yougurt?"
            },


            {
              subHeading: "3. Context:",
              description:
                "Include All Necessary Information. Provide all relevant details in each prompt, as Chat with documents does not retain context from previous responses.",
            },

            {
              subHeading: "4. Brackets",
              description:
                "Give Clear Instructions. Use brackets to specify your request clearly.Example: [Translate the following sentence to informal French.] How’s it going?”",
            },


            {
              subHeading: "5. Refine:",
              description:
                "Improve Your Prompts. If the initial response isn't satisfactory, adjust your prompt. Experiment with different lengths and phrasing to enhance the results.",
            },


            { summary: "Verify key details by checking the source below the response.Keep refining your prompts for optimal results!" }

          ],
        },


        {
          heading: "Which AI Model Should I Use?",
          subHeadings: [
            {
              subHeading: "1. Gemini 1.F Flash",
              description:
                "Gemini 1.F Flash is designed for high-speed processing and efficient performance, making it suitable for applications requiring quick responses and real-time data handling.",
            },
            {
              subHeading: "Speed:",
              description:
                "Optimized for fast processing and low latency.",
            },
            {
              subHeading: "Efficiency:",
              description:
                "High efficiency in handling large volumes of data.",
            },
            {
              subHeading: "Best For:",
              description:
                "Real-time applications, fast data processing tasks, and scenarios where speed is critical.",
            },
            {
              subHeading: "2. GPT-4",
              description:
                "GPT-4 is a state-of-the-art language model known for its advanced natural language understanding and generation capabilities. It offers comprehensive performance across a variety of tasks.",
            },
            {
              subHeading: "Versatility:",
              description:
                "Capable of handling a wide range of language tasks.",
            },
            {
              subHeading: "Contextual Understanding:",
              description:
                "Deep understanding of context and nuance in language.",
            },
            {
              subHeading: "Best For:",
              description:
                "Complex language generation tasks, sophisticated content creation, and conversational agents.",
            },
            {
              subHeading: "3. GPT-4o Mini",
              description:
                "GPT-4o Mini is a scaled-down version of GPT-4, designed to be more resource-efficient while retaining much of the core functionality of its larger counterpart.",
            },
            {
              subHeading: "Resource Efficiency:",
              description:
                "Requires fewer computational resources than GPT-4.",
            },
            {
              subHeading: "Performance:",
              description:
                "Maintains high-quality outputs with reduced resource demands.",
            },
            {
              subHeading: "Best For:",
              description:
                "Scenarios where resource constraints are a concern, but high-quality language capabilities are still needed.",
            },
            {
              subHeading: "4. Llama 3",
              description:
                "Llama 3 is known for its innovative architecture and ability to handle complex queries with high accuracy.",
            },
            {
              subHeading: "Advanced Architecture:",
              description:
                "Utilizes cutting-edge technology for improved performance.",
            },
            {
              subHeading: "High Accuracy:",
              description:
                "Designed to deliver accurate results in complex scenarios.",
            },
            {
              subHeading: "Best For:",
              description:
                "Specialized applications requiring high accuracy and advanced capabilities.",
            },
            {
              subHeading: "5. Llama 70 Billion",
              description:
                "Llama 70 Billion is a large-scale model with extensive training and massive parameter count, offering exceptional performance for highly demanding tasks.",
            },
            {
              subHeading: "Large Scale:",
              description:
                "Exceptional performance due to its large number of parameters.",
            },
            {
              subHeading: "High Capacity:",
              description:
                "Capable of handling the most complex tasks with ease.",
            },
            {
              subHeading: "Best For:",
              description:
                "Extremely complex applications requiring the highest performance levels.",
            },
            {
              summary: "For more detailed information and to ensure you select the most suitable AI model for your needs, review the specifications and capabilities of each model carefully. Consider your specific requirements and constraints when making a decision."
            },
          ],

        },



        {
          heading: "View or Delete Message History",
          subHeadings: [
            {
              subHeading: "1. Generate a Response",
              description:
                "Type a prompt into the chat box and generate an answer.",
            },
            {
              subHeading: "2. View Message History",
              description:
                "To view the message history, click the 'History' download button located in the left corner of the right-hand sidebar of the page.",
            },
            {
              subHeading: "3. Download History",
              description:
                "You will receive a .txt file containing your message history.",
            },
            {
              subHeading: "4. Delete Message History",
              description:
                "To delete the message history, click the 'Bin' button on the right side of the page.",
            },
            {
              subHeading: "5. Confirm Deletion",
              description:
                "A confirmation popup will appear. Click 'Yes' to confirm the deletion.",
            },
          ],

        }

      ],
    },
  ];


  const categories = ["all", "Chat With Docs"]; // List of categories

  const filteredTutorials =
    selectedCategory === "all"
      ? data
      : data.filter((tutorial) => tutorial.category === selectedCategory);

  return (
    <div className='  max-w-screen-2xl m-auto px-0 md:px-5 overflow-hidden'>

      <div style={{ marginTop: "180px" }}>
        <div className="video-container">
          <video controls>
            <source src={chatdocsVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <div
        className="tutorial-box"

        style={{
          display: "flex",
          overflow: "auto",
          marginTop: "100px",
     
        }}
      >

        <div  className="tutorial-subjects">
          <div style={{ display: "flex", flexDirection: "column", }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "10px",
                
              }}
              className="fixed-top"
            >
              <h5 className="text-secondary font-bold">
                Subjects
              </h5>
              <FaBookOpenReader className=" text-secondary"  />
            </div>
            <div className="luxury-border" />
            <div style={{ flex: "1", margin: "10px", marginTop: "20px", }}>
              {categories.map((category) => (
                <div
                  key={category}
                  onClick={() => setSelectedCategory(category)}
                  style={{
                    cursor: "pointer",
                    padding: "5px",
                    margin: "5px 0",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p style={{ textTransform: "capitalize", fontWeight: "bold" }} >
                    {category}
                  </p>

                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={{ flex: "1", margin:" 0px 10px"}}>
          <div style={{ textAlign: "center" }}>
      
            {filteredTutorials?.map((item) => (
              <div key={item.id}>
                {item.longDesc.map((subItem, index) => (
                  <div
                    onClick={() => toggleShowMore(index)}
                    className={`box  bg-primary item  ${toggleShowMore ? 'show' : 'hide'}`}
                    key={subItem.heading}
                    style={{ borderTop: "1px solid #b3b3b329", display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "center", marginTop: "0px", padding: "0",  cursor: "pointer",border:"1px solid #c6c6c6" }}

                  >
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "0px", padding: "5px 20px", width: "100%"  }}>
                      <div>
                        <h5 className="font-semibold" style={{ textAlign: "left", marginTop: "10px", fontSize: "22px" }}>
                          {subItem.heading}
                        </h5>
                        <p
                          className=" bg-[#f58220]"
                          style={{
                            fontWeight: "bold",
                            padding: "8px 8px",
                            borderRadius: "5px",
                            width: "180px",
                            marginTop: "10px",
                          
                          }}
                        >
                          {item.title}
                        </p>
                      </div>
                      <img
                        width="250px"
                        src={item.image}
                        alt={item.title}
                        style={{ marginTop: "20px" }}
                      />
                    </div>
                    <div style={{ marginTop: "20px", padding: "10px 20px",width:"100%"}}>
                    
                      {showMore === index && (
                        <div style={{ textAlign: "left", marginTop: "20px" }}>
                          {subItem.subHeadings.map((subItem, subIndex) => (
                            <div key={subIndex}>
                              <p>{subItem.subHeading}</p>
                              <p style={{ color: "grey" }}>{subItem.description}</p>
                              <p style={{ marginTop: "10px" }} >{subItem.summary}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Tutorials