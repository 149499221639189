import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { flyerData } from "../assets/data"

function SliderCarousal() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    cssEase: "linear",
    speed: 2000,
    autoplaySpeed: 10000,
    arrows: false
  };

  return (
    <section style={{ marginTop: "105px" }} id="home" className='slider-carousal mt-24 md:mt-28 bg-gradient-to-r from-[#a4d1ff]   via-[#cfe8ff] to-[#edfaff] md:mx-0 mx-5 rounded-2xl '>
      <Slider {...settings}>
        {flyerData?.map((data) => {
          return (
            <div>
              <div className='slider-item px-10   xl:px-5' >
                <div className='left-content-slider' >

                  <h1 className='slider-carousal-heading  font-bold  text-center sm:text-left text-2xl sm:text-3xl md:text-4xl lg:text-5xl mb-10  ' >{data.slogan}</h1>
                  <p className='slider-carousal-text  text-center sm:text-left text-lg'  >{data.desc}</p>
                  <div className=' submit-btn flex   mb-10'><a href='#contact' className='  bg-secondary py-3 md:py-4 px-8 rounded-3xl w-full md:w-fit font-semibold text-white mt-4'>Book a Call</a>
                  </div>             </div>
                <img src={data.img} className='hero-slider-image h-44  md:h-48  lg:h-72 xl:h-96 ' />

              </div>
            </div>
          )
        })}
      </Slider>
    </section>
  )
}

export default SliderCarousal


