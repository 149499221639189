// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDRmDe-R7SV9yNmSDFCLvZObBiI_KosDmU",
  authDomain: "gigantech-f62c0.firebaseapp.com",
  projectId: "gigantech-f62c0",
  storageBucket: "gigantech-f62c0.appspot.com",
  messagingSenderId: "946167374771",
  appId: "1:946167374771:web:bcc5fec905dcf5d9b3a1c6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
