import React, { useEffect } from "react";
import lairTechnology from "../assets/images/lairTechnology.png"
import nationalGrid from "../assets/images/nationalGrid.png"
import simens from "../assets/images/simens.png"
import BNSF from "../assets/images/BNSF.png"
import BWC from "../assets/images/BWC.png"
import bayerPharma from "../assets/images/bayerPharma.png"
import railcomm from "../assets/images/railcom.png"
import smachstack from "../assets/images/smachstack.png"

import Aos from "aos";
import 'aos/dist/aos.css';

const logos = [
  { src: BNSF, alt: "Zoom" },
  { src: BWC, alt: "Atlassian" },
  { src: smachstack, alt: "Smachstack" },
  { src: bayerPharma, alt: "Eyratech" },
  { src: simens, alt: "Eyratech" },
  { src: nationalGrid, alt: "Eyratech" },
  { src: lairTechnology, alt: "Eyratech" },

];

function OurClients() {
  useEffect(() => {
    Aos.init();
  }, [])


  return (
    <div className="mb-12">
      <div className=" flex  items-center flex-col  ">
        <h1 className=" curved-heading font-bold text-3xl md:text-4xl ">
          Our Clients
        </h1>
        <p className=" mt-3">
          We have been working with clients around the world.
        </p>
      </div>

      <div className="scroll-container">
        <div className="scroll-content mt-10">
          {/* Dynamically render the content */}
          {logos.map((logo, index) => (
            <div className="item" key={index}>
              <img className="m-auto sm:w-60 h-24 object-contain" src={logo.src} alt={logo.alt} />
            </div>
          ))}

          {/* Duplicate content for infinite scroll */}
          {logos.map((logo, index) => (
            <div className="item" key={`duplicate-${index}`}>
              <img className="m-auto sm:w-60 h-24 object-contain" src={logo.src} alt={logo.alt} />
            </div>
          ))}
        </div>
      </div>




    </div>
  );
}

export default OurClients;
