import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import arrowblue from "../assets/images/arrowblue.svg";

const services = [
  {
    id: 1,
    title: "Elevate Your Business with AI Transformation Services",
    description: "Unlock AI's potential with our AI Transformation Services. Elevate operations, enhance experiences, and drive business growth.",
    icon: (
      <svg fill="none" viewBox="0 0 72 72" className="h-16 w-16 hover:astro-A2Y6KUSK">
        <rect width="11" height="34" x="15" y="24" fill="#00AEEF" rx="5"></rect>
        <rect width="34" height="34" x="53" y="53" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" rx="3" transform="rotate(180 53 53)"></rect>
        <rect width="44" height="44" x="14" y="14" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" rx="6"></rect>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M58 34h8a2 2 0 0 1 2 2v0a2 2 0 0 1-2 2h-8v-4Z"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M58.5 24H67"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M58.5 48H67"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 34H6a2 2 0 0 0-2 2v0a2 2 0 0 0 2 2h8v-4Z"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.5 24H5"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.5 48H5"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M38 58v8a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2v-8h4Z"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M48 58.5V67"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M24 58.5V67"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M38 14V6a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v8h4Z"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M48 13.5V5"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M24 13.5V5"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m30 42 1.74-11.304A2 2 0 0 1 33.715 29h1.568a2 2 0 0 1 1.977 1.696L39 42"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M43 29v13"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M31 37h7"></path>
        <circle cx="8.5" cy="14.5" r="2.5" fill="#0563AC"></circle>
        <circle cx="14.5" cy="6.5" r="1.5" fill="#0563AC"></circle>
      </svg>
    ),
  },
  {
    id: 2,
    title: "Expert Custom Software Development Tailored for Your Business",
    description: "At GiganTech, we create custom software solutions for your business needs. Swift, cost-effective, and comprehensive solutions from start to finish.",
    icon: (
      <svg fill="none" viewBox="0 0 72 72" className="h-16 w-16 hover:astro-A2Y6KUSK">
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M65 19.5V14a6 6 0 0 0-6-6H11a6 6 0 0 0-6 6v35a6 6 0 0 0 6 6h10.5"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 17h49"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 13h1"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 13h1"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 13h1"></path>
        <path fill="#00AEEF" d="M48.498 37A7.498 7.498 0 0 0 41 44.502 7.497 7.497 0 0 0 48.498 52C52.638 52 56 48.642 56 44.502A7.504 7.504 0 0 0 48.498 37Z"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M46.998 34C42.025 34 38 38.03 38 43.002A8.996 8.996 0 0 0 46.998 52C51.965 52 56 47.97 56 43.002 56 38.035 51.97 34 46.998 34Z"></path>
        <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M42.448 22.985a20.311 20.311 0 0 0-5.93 2.325c.531 1.889.079 4.004-1.41 5.493a5.535 5.535 0 0 1-3.936 1.626c-.573 0-1.152-.09-1.704-.258A20.304 20.304 0 0 0 27 37.985c1.815.931 3.073 2.799 3.073 4.983 0 2.183-1.258 4.046-3.073 4.977a20.365 20.365 0 0 0 2.468 5.814 5.727 5.727 0 0 1 1.704-.258 5.53 5.53 0 0 1 3.936 1.62c1.489 1.49 1.941 3.6 1.41 5.493a20.371 20.371 0 0 0 5.93 2.326c.978-1.652 2.757-2.778 4.82-2.778 2.062 0 3.709 1.026 4.708 2.573a20.235 20.235 0 0 0 5.909-2.589 5.62 5.62 0 0 1 1.541-5.02 5.55 5.55 0 0 1 3.952-1.625c.3 0 .6.021.9.068a20.389 20.389 0 0 0 2.472-6.098 5.591 5.591 0 0 1-2.283-4.498c0-1.847.905-3.53 2.456-4.69a20.357 20.357 0 0 0-5.93-2.325c.531-1.889.079-4.004-1.41-5.493a5.535 5.535 0 0 1-3.936-1.626c-.573 0-1.152.09-1.704.258a20.305 20.305 0 0 0-3.65-3.275z"></path>
      </svg>
    ),
  },
];

function Services() {

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className=" my-10 md:my-20   ">
      <div className="flex flex-col md:flex-row flex-wrap justify-between px-5 xl:px-0  our-service">
        {services.map(service => (
          <div
            key={service.id}
            data-aos="fade-up"
            data-aos-duration="1500"
            style={{ border: "1px solid #94959457" }}
            className="md:w-custom w-full md:p-4 p-2 pt-6 mb-14 rounded-md service-card group hover:shadow-lg transition-transform"
          >
            <div className="flex items-center gap-4">
              {service.icon} {/* Render the inline SVG here */}
              <h2 className="font-bold text-lg">{service.title}</h2>
            </div>
            <p className="mt-2 text-lg">{service.description}</p>
            <img
              className="mt-4 ml-2 w-4 arrow transition-transform transform -translate-x-8 opacity-0 group-hover:translate-x-0 group-hover:opacity-100 duration-300"
              src={arrowblue}
              alt="Arrow Icon"
            />
          </div>
        ))}

      </div>
    </div>
  );
}

export default Services;
