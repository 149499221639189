import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import FooterTwo from './components/FooterTwo';
import IndustryDetails from './components/IndustryDetails';
import AiProducts from './components/AiProducts';
import About from './components/About';
import ContactUs from './components/ContactUs';
import Tutorials from './components/Tutorials';


function App() {
  return (
    <div >
      <Router>
        {/* navbar  */}
        <Navbar />
        <Routes>
       
          <Route path="/" element={<HomePage/>} /> 
          <Route path="/contact" element={<ContactUs/>} /> 
          <Route path="/tutorials" element={<Tutorials/>} /> 


          <Route path="/products" element={<AiProducts/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/:industries/:industry" element={<IndustryDetails />} />
        </Routes>
        <FooterTwo />
      </Router>
    </div>
  );
}

export default App;
