import React from "react";

function OurClientsSpeak() {
  return (
    <div className="mb-12 mt-10 md:mt-20 ">
      <div className=" flex  items-center flex-col  ">
        <h1 className=" curved-heading font-bold text-3xl md:text-4xl text-center">
          Leader In Software Development
        </h1>
        <div className=" max-w-[64rem] mt-5 md:mt-10 px-5 md:px-2 ">
          <p className="   text-lg text-center">
            We are a global leader in software development, harnessing the power of artificial intelligence and the Internet of Things (IoT) to drive innovation and transform industries. With a passion for creating cutting-edge solutions, we specialize in developing software that not only enhances operational efficiency but also elevates user experiences across various sectors.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OurClientsSpeak;
