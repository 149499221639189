import React from 'react'
import techbg4 from "../assets/images/techbg4.jpg"
import aboutus from "../assets/images/aboutus.jpg"

function About() {
    return (
        <div>
            <div className='about-us-wrapper flex items-center justify-center '>
                <div className=' text-white flex flex-col  items-center max-w-[700px] text-center  '><h2 className=' text-3xl font-semibold'>About Us</h2>
                    <p className=' mt-5'>At Gigantech, we blend innovation and expertise in AI and software development to empower businesses. Partner with us to transform your ideas into reality and redefine what's possible in technology!
                    </p>
                </div>
            </div>

            {/* <div className=' relative h-96 mt-32 ml-20'>
                    <div className='absolute   border-8 border-white border outline-dashed outline-1   '>
                        <img className=' w-96 h-80' src={techbg4 } />
                    </div>
                    <div  className=' absolute left-60 -top-28   border-8 border-white border outline-dashed outline-1   '> <img className='w-72 h-60' src={aboutus } />
                    </div>
                </div> */}
        </div>
    )
}

export default About