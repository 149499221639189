
import ecommerce1 from "../assets/images/ecommerecenew.png"
import rail1 from "../assets/images/railnew.png"
import education from "../assets/images/educationnew.png"
import iots from "../assets/images/iotnew.png"
import Semiconductors from "../assets/images/semiconductornew.png"
import ai from "../assets/images/ainew.png"
import healthcare from "../assets/images/healthcarenew.png"
import electric from "../assets/images/electricutilitynew.png"
import hardware from "../assets/images/hardwarenew.png"


import multichannel from "../assets/images/multichannel.svg"
import customersupport from "../assets/images/customersupport.svg"
import dataanalysis from "../assets/images/dataanalysis.svg"




const industryData = {
    rail: {
        title: 'Rail',
        description: 'Standard-compliant, resilient, and cutting-edge solutions.',
        image: rail1,
        mainText: "GiganTech assists companies in the rail industry in addressing contemporary challenges by offering standard-compliant firmware, hardware, and web-based application development services. With our technical expertise and deep understanding of European and North American railways, we have established ourselves as a leading service provider in the sector.",

    },
    ecommerce: {
        title: 'E-Commerce',
        description: 'Transforming your vision into a thriving online marketplace.',
        image: ecommerce1,
        mainText: "We specialize in crafting robust, user-friendly e-commerce platforms designed to elevate customer experiences and drive sales. Our expert team blends cutting-edge technology with industry best practices to create customized solutions that cater to your specific needs. From seamless payment integration to responsive design and advanced data analytics, we ensure your online store is not only functional but also engaging and impactful. Explore how our e-commerce expertise can help you unlock new revenue streams and achieve your business objectives in today’s dynamic digital landscape.",

        details: [
            { title: "Data Analysis and Insights", img: dataanalysis, description: "Offering data-driven insights and recommendations to enhance marketing, sales, and inventory management." },
            { title: "Multi-channel Selling", img: multichannel, description: "Broadening the business's reach by selling through multiple online mobile platforms, websites, and marketplaces." },
            { title: "Customer Support and Chatbots", img: customersupport, description: "Integrating chatbots and customer support functionalities to improve customer service and respond to inquiries in real-time." }
        ]
    },
    iot: {
        title: 'IoT',
        description: 'Connecting the future, one smart solution at a time.',
        image: iots,
        mainText: "At GiganTech, we harness the power of IoT to create smart, interconnected systems that enhance efficiency and drive innovation across various industries. Our expert team specializes in developing scalable IoT solutions that provide real-time data insights, streamline operations, and improve user experiences. Whether  you're looking to optimize your supply chain, enhance smart home technologies, or implement industrial IoT applications, we are here to guide you on your journey to  a more connected future. Explore how our IoT expertise can help you unlock new  opportunities and achieve your business goals."
    },
    industrialAutomation: {
        title: 'Industrial Automation',
        description: 'Transforming spaces into smart, connected environments.',
        image: ecommerce1,
        mainText: "In Home & Industrial Automation we specialize in developing smart automation solutions that enhance efficiency, safety, and comfort in both residential and  industrial settings. Our expert team integrates advanced technologies to create customized systems that streamline operations, optimize resource use, and elevate user experiences. From smart home devices that simplify daily tasks to industrial automation systems that improve productivity and reduce downtime, we are dedicated to transforming your environment into a seamless, connected ecosystem.Explore how our automation solutions can redefine your space and elevate your  lifestyle or business."
    },
    electricutility: {
        title: 'Electric Utility',
        description: 'Powering the future with innovative utility solutions.',
        image: electric,
        mainText: "Welcome to our Electric Utility Solutions section, where we empower the energy sector with innovative technology. At Gigantech, we specialize in providing comprehensive software and hardware solutions designed to enhance the efficiency, reliability, and sustainability of electric utilities. Our expert team integrates advanced analytics, smart grid technology, and IoT solutions to optimize energy management and improve operational performance. Whether you're  looking to enhance grid reliability, streamline customer service, or implement renewable energy sources, we are committed to helping you navigate the evolving energy landscape. Discover how our electric utility solutions can drive your business forward and support a sustainable future."
    },
    ai: {
        title: 'Artificial Intelligence',
        description: 'Intelligence-driven solutions for digital transformation.',
        image: ai,
        mainText: "We assist our clients in integrating artificial intelligence solutions into their business processes and operations through swift digital transformation. Our team   is dedicated to creating an ecosystem of connected digital devices that prioritize user experience and adhere to industry best practices. We enable you to unlock future business opportunities, evolving from AI implementations to fully autonomous systems. Leveraging technologies such as machine learning, deep learning, data analytics, and robotic process automation, we empower you to enhance productivity and make quicker, more informed business decisions."
    },
    hardwareengineering: {
        title: 'Hardware Engineering',
        description: ' Empowering innovation through precision-engineered hardware solutions.',
        image: hardware,
        mainText: "Welcome to our Hardware engineering division, where precision meets innovation. At GiganTech, we specialize in designing and developing high-performance hardware solutions tailored to meet the unique needs of your business. Our expert team combines deep technical knowledge with industry best practices to create robust, reliable, and efficient systems. Whether you're looking to enhance existing products or develop new technologies, we are committed to delivering solutions that drive success and elevate your operations. Discover how our hardware engineering expertise can help you achieve your goals and stay ahead in a rapidly evolving market."
    },
    semiconductors: {
        title: 'Semiconductors',
        description: ' Driving innovation through advanced semiconductor solutions.',
        image: Semiconductors,
        mainText: "Welcome to our Semiconductor Solutions section, where innovation powers the future of technology. At GignaTech, we specialize in developing advanced microprocessor based solutions that drive performance and efficiency across a wide range of applications. Our team combines deep industry knowledge with cutting-edge technology to deliver reliable and high-quality semiconductor solutions tailored to meet the evolving needs of your business. From microprocessors to integrated circuits, we are committed to enabling advancements in sectors such as automotive, telecommunications, and consumer electronics."
    },


    healthcarelifestyle: {
        title: 'Healthcare & Lifestyle',
        description: 'Bridging Wellness and Technology for a Healthier Tomorrow.',
        image: healthcare,
        mainText: "In today's fast-paced world, the fusion of healthcare and lifestyle management is essential for promoting overall well-being. As a leading software development company, we are dedicated to creating innovative solutions that empower individuals, hospitals, and healthcare providers to enhance their health journeys. Our cutting-edge applications ranging from personalized fitness trackers to comprehensive telehealth platforms are designed to facilitate seamless interactions between patients and physicians. With the integration of artificial intelligence, our software solutions provide valuable insights that improve decision-making and personalize care experiences. By bridging the gap between healthcare and everyday living, we help users make informed choices that lead to better health outcomes. Explore how our transformative technology can foster a healthier, more balanced life for everyone in the healthcare ecosystem."
    },


    education: {
        title: 'Education',
        description: ' Empowering learners through AI-driven innovation.',
        image: education,
        mainText: "Welcome to our Education Apps section, where technology meets the future of learning. At GiganTech, we specialize in developing innovative educational applications that harness the power of AI to enhance the learning experience. Our team combines cutting-edge technology with pedagogical expertise to create apps that adapt to various learning styles, providing personalized content and feedback. From immersive interactive lessons to intelligent progress tracking, our solutions empower both educators and students to achieve their full potential. Join us in transforming education and unlocking new pathways to knowledge with our AI-driven education apps",

      
    },
   
};



export default industryData;
