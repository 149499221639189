import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import python from "../assets/images/webdevolpemnticons/python.svg";
import js from "../assets/images/webdevolpemnticons/js.svg";

import typescript from "../assets/images/webdevolpemnticons/typescript.svg";
import nodejs from "../assets/images/webdevolpemnticons/nodejs.svg";
import expressjs from "../assets/images/webdevolpemnticons/expressjs.svg";
import django from "../assets/images/webdevolpemnticons/django.svg";
import flask from "../assets/images/webdevolpemnticons/flask.svg";
import java from "../assets/images/webdevolpemnticons/java.svg";
import mongodb from "../assets/images/webdevolpemnticons/mongodb.svg";
import postgress from "../assets/images/webdevolpemnticons/postgress.svg";
import mysql from "../assets/images/webdevolpemnticons/mysql.svg";
import react from "../assets/images/webdevolpemnticons/react.png";
import angular from "../assets/images/webdevolpemnticons/angular.svg";
import html from "../assets/images/webdevolpemnticons/html.svg";
import sass from "../assets/images/webdevolpemnticons/sass.svg";
import tailwindcss from "../assets/images/webdevolpemnticons/tailwindcss.svg";
import mui from "../assets/images/webdevolpemnticons/mui.svg";
import css from "../assets/images/webdevolpemnticons/css.svg";
import adobxd from "../assets/images/webdevolpemnticons/adobxd.svg";

import docker from "../assets/images/webdevolpemnticons/docker.svg";
import jquery from "../assets/images/webdevolpemnticons/jquery.svg";
import kuberneter from "../assets/images/webdevolpemnticons/kuberneter.svg";

import tensorflow from "../assets/images/aiIcons/tensorflow.svg";
import pytorch from "../assets/images/aiIcons/pytorch.svg";
import pandas from "../assets/images/aiIcons/pandas.svg";
import numpy from "../assets/images/aiIcons/numpy.svg";
import scikit from "../assets/images/aiIcons/scikit.png";
import headop from "../assets/images/aiIcons/headop.png";
import apachespark from "../assets/images/aiIcons/apachesaprk.png";
import apache from "../assets/images/aiIcons/apache.png";


import ros from "../assets/images/industrialautomationIcons/ros.png";
import respberry from "../assets/images/industrialautomationIcons/respberry.png";
import realtimelinus from "../assets/images/industrialautomationIcons/realtimelinus.png";
import plc2 from "../assets/images/industrialautomationIcons/plc.png";
import opencv from "../assets/images/industrialautomationIcons/opencv.png";
import microsoftazureiot from "../assets/images/industrialautomationIcons/microsoftazureiot.png";
import freertos from "../assets/images/industrialautomationIcons/freertos.png";
import cplus from "../assets/images/industrialautomationIcons/cplus.png";
import c from "../assets/images/industrialautomationIcons/c.png";
import awsiot from "../assets/images/industrialautomationIcons/awsiot.png";
import arduino from "../assets/images/industrialautomationIcons/ardino.png";

import arm from "../assets/images/embeddedIcons/arm.png";
import avr from "../assets/images/embeddedIcons/avr.png";
import Bluetooth from "../assets/images/embeddedIcons/Bluetooth.png";
import DeviceDrivers from "../assets/images/embeddedIcons/DeviceDrivers.png";
import Eclipse from "../assets/images/embeddedIcons/Eclipse.png";
import Keil from "../assets/images/embeddedIcons/Keil.png";
import memorydevices from "../assets/images/embeddedIcons/memorydevices.png";
import Middleware from "../assets/images/embeddedIcons/Middleware.png";
import peripheral from "../assets/images/embeddedIcons/peripheral.png";
import Realtimeoperatingsystem from "../assets/images/embeddedIcons/Realtimeoperatingsystem.png";
import renesas from "../assets/images/embeddedIcons/renesas.png";
import ssl from "../assets/images/embeddedIcons/ssl.png";
import stm from "../assets/images/embeddedIcons/stm.png";
import wifi from "../assets/images/embeddedIcons/wifi.png";
import zigbee from "../assets/images/embeddedIcons/zigbee.png";
import android from "../assets/images/mobileicons/android.png"
import flutter from "../assets/images/mobileicons/flutter.png"
import ios from "../assets/images/mobileicons/ios.svg"
import kotlin from "../assets/images/mobileicons/kotlin.svg"
import netcore from "../assets/images/mobileicons/netcore.svg"
import reactnative from "../assets/images/mobileicons/reactnative.svg"
import maui from "../assets/images/mobileicons/maui.svg"

import Aos from "aos";
import { CategorySection } from "./CategorySection";



const categories = [
  {
    title: "Artificial Intelligence",
    technologies: [
      { name: "Scikit-learn", imgSrc: scikit },
      { name: "PyTorch", imgSrc: pytorch },
      { name: "Pandas", imgSrc: pandas },
      { name: "Tensorflow", imgSrc: tensorflow },
      { name: "Numpy", imgSrc: numpy },
      { name: "Apache Spark", imgSrc: apachespark },
      { name: "Headop", imgSrc: headop },
      { name: "Apache", imgSrc: apache },
    ],
  },
  {
    title: "Full Stack Development",
    technologies: [
      { name: "Javascript", imgSrc: js },
      { name: "Typescript", imgSrc: typescript },
      { name: "Node JS", imgSrc: nodejs },
      { name: "Express js", imgSrc: expressjs },
      { name: "Django", imgSrc: django },
      { name: "Flask", imgSrc: flask },
      { name: "Java", imgSrc: java },
      { name: "Python", imgSrc: python },
      { name: "Mongo DB", imgSrc: mongodb },
      { name: "Postgress DB", imgSrc: postgress },
      { name: "MySQL", imgSrc: mysql },
      { name: "React JS", imgSrc: react },
      { name: "Angular JS", imgSrc: angular },
      { name: "HTML", imgSrc: html },
      { name: "CSS", imgSrc: css },
      { name: "SCSSS", imgSrc: sass },
      { name: "Tailwind UI", imgSrc: tailwindcss },
      { name: "Material UI", imgSrc: mui },
      { name: "Adobe XD", imgSrc: adobxd },
      { name: "Docker", imgSrc: docker },
      { name: "J Query", imgSrc: jquery },
      { name: "Kubernetes", imgSrc: kuberneter },
    ],
  },
  {
    title: "Mobile Application Development",
    technologies: [
      { name: "Android", imgSrc: android },
      { name: "IOS", imgSrc: ios },
      { name: "React Native", imgSrc: reactnative },
      { name: "Flutter", imgSrc: flutter },
      { name: "Kotlin", imgSrc: kotlin },
      { name: "Net Core", imgSrc: netcore },
      { name: "MAUI", imgSrc: maui },
    ],
  },

  {
    title: "Mobile Application Development",
    technologies: [
      { name: "Android", imgSrc: android },
      { name: "IOS", imgSrc: ios },
      { name: "React Native", imgSrc: reactnative },
      { name: "Flutter", imgSrc: flutter },
      { name: "Kotlin", imgSrc: kotlin },
      { name: "Net Core", imgSrc: netcore },
      { name: "MAUI", imgSrc: maui },
    ],
  },
  {
    title: "Industrial Automation",
    technologies: [
      { name: "ROS", imgSrc: ros },
      { name: "Raspberry Pi", imgSrc: respberry },
      { name: "RTLinux", imgSrc: realtimelinus },
      { name: "PLC2", imgSrc: plc2 },
      { name: "Microsoft IOT", imgSrc: microsoftazureiot },
      { name: "FreeRTOS", imgSrc: freertos },
      { name: "C++", imgSrc: cplus },
      { name: "C", imgSrc: c },
      { name: "AWS", imgSrc: awsiot },
      { name: "Arduino", imgSrc: arduino },
      { name: "OpenCV", imgSrc: opencv },
    ],
  },
  {
    title: "Embedded Solutions",
    technologies: [
      { name: "ARM", imgSrc: arm },
      { name: "AVR", imgSrc: avr },
      { name: "Bluetooth", imgSrc: Bluetooth },
      { name: "Device driver", imgSrc: DeviceDrivers },
      { name: "Eclipse", imgSrc: Eclipse },
      { name: "Keil", imgSrc: Keil },
      { name: "Memory devices", imgSrc: memorydevices },
      { name: "Middleware", imgSrc: Middleware },
      { name: "Peripheral Devices", imgSrc: peripheral },
      { name: "RTOS", imgSrc: Realtimeoperatingsystem },
      { name: "Renesas", imgSrc: renesas },
      { name: "SSL", imgSrc: ssl },
      { name: "STM", imgSrc: stm },
      { name: "Wifi", imgSrc: wifi },
      { name: "Zigbee", imgSrc: zigbee },
    ],
  },
];


function OurStack() {
  useEffect(() => {
    Aos.init();
  }, [])

  return (
    <div className="mb-12 mt-10 md:mt-20 ">
      <div className=" flex  items-center flex-col   ">
        <h1 className=" curved-heading font-bold text-3xl md:text-4xl text-center">
          Software Development Stack
        </h1>
        <div className=" max-w-[48rem] mt-2  md:mt-10">
          <p className="  mt-2 text-base text-center text-[#606f7b]">
            Our developers utilize the latest technologies, frameworks, and components to
            build solutions that integrate a robust technical back-end with a user-friendly,
            modern design.
          </p>
        </div>
      </div>
      <div className="mt-10 md:mt-20">
        {categories.map((category, index) => (
          <CategorySection key={index} category={category} />
        ))}

      </div>
    </div>
  );
}

export default OurStack;
