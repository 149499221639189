import React from "react";

import ai from "../assets/images/ai.png"
import iot from "../assets/images/iot.png"
import eutility from "../assets/images/eutility.png"
import electricengineering from "../assets/images/electricengineering.png"
import industrialautomation from "../assets/images/industrialautomation.png"
import rail from "../assets/images/rail.png"

import educationIcon from "../assets/images/educationicon.svg"
import semiconductorIcon from "../assets/images/semiconductorIcon.svg"
import ecommereceIcon from "../assets/images/ecommereceIcon.svg"
import { Link, NavLink } from "react-router-dom";

const industries = [
  { title: "Rail", link: "/industries/rail", icon: rail },
  { title: "E-Commerce", link: "/industries/ecommerce", icon: ecommereceIcon },
  { title: "Education", link: "/industries/education", icon: educationIcon },
  { title: "Semiconductors", link: "/industries/semiconductors", icon: semiconductorIcon },
  { title: "IoT", link: "/industries/iot", icon: iot },
  { title: "Healthcare & Lifestyle", link: "/industries/healthcarelifestyle", icon: industrialautomation },
  { title: "Electric Utility", link: "/industries/electricutility", icon: eutility },
  { title: "Artificial Intelligence", link: "/industries/ai", icon: ai },
  { title: "Hardware Engineering", link: "/industries/hardwareengineering", icon: electricengineering },
];

function IndustriesWorkFor() {
  return (
    <div className=" mb-12 mt-12 md:mt-24">
      <div className=" flex  items-center flex-col  ">
        <h1 className="curved-heading font-bold text-3xl md:text-4xl text-center">
          Industries that Benefited
        </h1>
        <p className=" text-[#606f7b] mt-5 text-center">
          Applying our best practices and processes, we serve a
          variety of industries that benefit from our services
        </p>
      </div>

      <div className="mt-5 md:mt-10">
      <div className="bg-gradient-to-r from-[#d6eafd9d] via-[#d1e3f5] to-[#edfaff] grid gap-4 p-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
        {industries.map((industry, index) => (
          <Link
            key={index}
            to={industry.link}
            className="border border-neutral bg-white rounded hover:bg-primary flex flex-col justify-center items-center shadow-sm py-3 px-2 h-[145px]"
          >
            <img
              src={industry.icon}
              className="object-contain fold:w-1/4 h-10 fold:h-1/4"
              alt={`Industry ${industry.title} Icon`}
            />
            <p className="mt-2 font-JostMedium fold:text-sm mobile:text-lg mobile:leading-6 laptop:text-lg laptop:leading-7">
              {industry.title}
            </p>
          </Link>
        ))}
      </div>
    </div>
    </div>
  );
}

export default IndustriesWorkFor;
