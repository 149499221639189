// GenericIndustryDetail.js
import React from 'react';

const GenericIndustryDetail = ({ title, content, industrydetails, mainText }) => {
    return (
        <div style={{  }}>
            <div className='max-w-screen-2xl m-auto px-5 xl:px-0' >
                {content}
          
                {industrydetails && industrydetails.length > 0 && (
                    <div className="flex md:flex-row flex-col justify-between mt-5">
                        {industrydetails.map((detail, index) => (
                            <div className='card text-center' key={index}>
                                {detail.img && <img className='w-16 object-contain mb-5 m-auto' src={detail.img} alt={detail.title} />}
                                {detail.title && <h3 className='mb-2 font-normal text-lg  md:text-xl leading-7'>{detail.title}</h3>}
                                {detail.description && <p class="text-base  md:text-lg md:leading-6 text-[#606f7b] mt-2.5">{detail.description}</p>}
                            </div>
                        ))}
                    </div>
                )}
            </div>

        </div>
    );
};

export default GenericIndustryDetail;
