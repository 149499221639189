import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Intro() {

    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div data-aos="zoom-in-up" data-aos-duration="3000" className='   flex items-center relative mt-10'>
            <div class="mx-auto max-w-7xl px-6 lg:px-8 ">
                <div class="mx-auto max-w-2xl lg:max-w-5xl">
                    <p class=" tracking-tighter text-lg text-center text-black ">

                        <span >Welcome to Gigantech,</span> where innovation meets expertise in AI and software
                        development. Our team specializes in full-stack development, delivering cutting-
                        edge solutions that empower businesses to thrive in the digital landscape. With a
                        commitment to excellence and a passion for technology, we partner with clients to
                        transform their ideas into reality. Whether you’re looking to enhance your existing
                        applications or create new, scalable solutions, Gigantech is here to elevate your
                        vision. Join us on the journey to redefine what's possible in technology!
                    </p></div></div></div>

    )
}

export default Intro