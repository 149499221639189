import React, { useEffect, useState } from 'react';
import { BiPhoneCall, BiWindowAlt } from "react-icons/bi";
import bar from "../assets/images/bar.svg";
import close from "../assets/images/close.svg";
import logo5 from "../assets/images/logo6.png";
import IndustriesNavBar from './IndustriesNavBar';
import { useLocation } from 'react-router-dom';

const navItems = [
    { name: "Home", href: "/" },
    { name: "AI Products", href: "/products" },
    { name: "Industries" },
    { name: "About Us", href: "/about" }
];

const industriesForMobile = [
    { name: "Rail", href: "/industries/rail" },
    { name: "E-Commerce", href: "/industries/ecommerce" },
    { name: "Education", href: "/industries/education" },
    { name: "Semiconductors", href: "/industries/semiconductors" },
    { name: "IoT", href: "/industries/iot" },
    { name: "Healthcare & Lifestyle", href: "/industries/healthcarelifestyle" },
    { name: "Electric Utility", href: "/industries/electricutility" },
    { name: "Artificial Intelligence", href: "/industries/ai" },
    { name: "Hardware Engineering", href: "/industries/hardwareengineering" }
];


function Navbar() {
    const location = useLocation();
    const [activeItem, setActiveItem] = useState(null);
    const [hoveredLink, setHoveredLink] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isTooltipHovered, setIsTooltipHovered] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Update activeItem based on the current URL
    useEffect(() => {
        const currentItem = navItems.find(item => item.href === location.pathname);
        if (currentItem) {
            setActiveItem(currentItem.name);
        } else if (location.pathname.startsWith("/industries/")) {
            setActiveItem("Industries"); // Set "Industries" active for all industry links
        }
    }, [location.pathname]);

    const handleIndustryClick = () => {

        setActiveItem("Industries");
    };

    const handleMouseLeaveLink = () => {
        if (isTooltipHovered) {
            setHoveredLink(null);
        }

    };

    return (
        <nav className='h-24 bg-white shadow-custom xl:px-2 px-5 fixed top-0 w-full z-50'>
            <div className='h-24 flex justify-between items-center max-w-screen-2xl m-auto w-full relative z-50'>
                {hoveredLink?.name === "Industries" && (
                    <div
                        className='border border-1 rounded-md w-10/12 m-auto left-1/2 absolute top-24 shadow-custom industries-nav z-50'
                        style={{ transform: "translateX(-50%)" }}
                        onMouseEnter={() => setIsTooltipHovered(true)}
                        onMouseLeave={() => {
                            setIsTooltipHovered(false);
                            setHoveredLink(null);
                        }}

                        onClick={()=>{
                            setIsTooltipHovered(false);
                            setHoveredLink(null); // Hide when leaving the tooltip
                        }}
                    >
                        <IndustriesNavBar onIndustryClick={handleIndustryClick} />
                    </div>
                )}
                <div className='font-medium flex gap-5'>
                    <a href='/'><img src={logo5} className='w-72' alt="Logo" /></a>
                </div>
                <div className='hidden gap-12 items-center lg:flex menu-items'>
                    {navItems.map((item, index) => (
                        <a
                            key={index}
                            href={item.href}
                            className={`text-base font-medium ${activeItem === item.name ? 'text-secondary curved-heading' : ''}`}
                            onMouseEnter={() => setHoveredLink(item)}
                            // onMouseLeave={() => setHoveredLink(null)}
                            onMouseLeave={handleMouseLeaveLink}
                            onClick={() => {
                                if (item.name === "Industries") {
                                    handleIndustryClick();

                                }
                            }}
                        >
                            {item.name}
                        </a>
                    ))}
                    <a  href="/contact" className='submit-btn  flex items-center bg-secondary py-3 px-6 rounded-2xl gap-3 contact'>
                        <BiPhoneCall color='white' fontSize={26} />
                        <span className='font-base text-white font-medium'>Contact us</span>
                    </a>
                </div>

                {/* Mobile Menu Toggle Icon */}
                <div className='lg:hidden cursor-pointer' onClick={toggleMenu}>
                    <img src={isMenuOpen ? close : bar} alt="Menu Toggle" />
                </div>

                {/* Mobile Menu */}
                <div className={`fixed inset-0 z-10 transition-transform transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} bg-black`}>
                    <div className='h-full bg-black p-4 flex flex-col'>
                        <div className='flex justify-between items-center w-full mb-3 border-b-2 pb-3'>
                            <div className='text-white text-lg font-medium'>
                                <img className='w-52' src={logo5} alt="Logo" />
                            </div>
                            <div className='cursor-pointer' onClick={toggleMenu}>
                                <img src={close} alt="Close Menu" />
                            </div>
                        </div>
                      
                        {navItems.map((item, index) => (
                            <div className='my-1' key={index}>
                                <a
                                    href={item.href}
                                    className='text-base font-medium text-white'
                                    onClick={() => {
                                        if (item.name === "Industries") {
                                            handleIndustryClick();
                                        }
                                    }}
                                >
                                    {item.name}
                                </a>
                            </div>
                        ))}
                        <a href="/contact" className=' submit-btn flex items-center justify-center bg-secondary py-4 px-8 rounded-2xl gap-3 mt-4'>
                            <BiPhoneCall color='white' fontSize={26} />
                            <a className='font-base text-white font-medium text-center'>Contact us</a>
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
