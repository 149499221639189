import React from 'react'
import SliderCarousal from '../components/SliderCarousal'
import Services from '../components/Services'
import OurClients from '../components/OurClients'
import OurClientsSpeak from '../components/OurClientsSpeak'
import OurStack from '../components/OurStack'
import IndustriesWorkFor from '../components/IndustriesWorkFor'
import OurProcess from '../components/OurProcess'
import Intro from '../components/Intro'
import ContactUs from '../components/ContactUs'

function HomePage() {
  return (
    <div className='max-w-screen-2xl m-auto px-0 md:px-5 overflow-hidden'>
        <SliderCarousal/>
        <Intro/>
        <Services/>
        <OurClients/>
        <OurClientsSpeak/>
        <OurStack/>
        <IndustriesWorkFor/>
        <OurProcess/>
        <ContactUs/>
    </div>
  )
}

export default HomePage