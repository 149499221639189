
import flyer1 from "./images/flyer1.png"
import rapidApp from "./images/rapidAppCarousal.png"
import embedded from "./images/embeddedCarousal.png"
import iot from "./images/iotCarousal.png"
import ai from "../assets/images/ainew.png"



export const flyerData=[
  {slogan:" Build Your Digital Future with Us",desc:"We offer comprehensive web development services, including innovative web apps, dynamic e-commerce sites, and custom SaaS solutions built on microservices architecture. Trust us to enhance your digital footprint.",img:flyer1},
  {slogan:" Commitment to Quality",desc:"Our quality assurance services guarantee that your software products are always of the highest quality. We are committed to ensuring your success.",img:flyer1},
  {slogan:"Rapid App Development",desc:"Explore our comprehensive solutions for Apple and Android app development, crafted to enhance your business solutions across a unified ecosystem.",img:rapidApp},
  {slogan:"Empower Your Business with AI-Driven Solutions",desc:"Leverage our AI application development expertise and seamless LLM integrations to revolutionize your business processes. Achieve operational excellence and competitive advantage with intelligent technology.",img:ai},
  {slogan:"Embedded Solutions compliant with industrial safety standards",desc:"We build high-end software for microcontrollers, microprocessors and SBCs using our extensive experience in embedded hardware design and development.",img:embedded},
  {slogan:"Connect, Monitor, Optimize with IoT",desc:"Our team has extensive experience of working with the IoT product development lifecycle. The lifecycle mainly includes R&D, Design Requirements, Hardware Components Selection.",img:iot}

]