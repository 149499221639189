import React from 'react'
import { useParams } from 'react-router-dom';
import GenericIndustryDetail from './GenericIndustryDetail';
import industryData from './IndustryData';

function IndustryDetails() {
    const { industry } = useParams();
    const industryInfo = industryData[industry]
    return (
        <div className='bg-primary' style={{ paddingTop: "130px",paddingBottom:"100px" }}>
            <div className='px-0 md:px-5 '>
                <GenericIndustryDetail industrydetails={industryInfo?.details} mainText={industryInfo?.mainText}  title={industryInfo.title} content={
                    <div className=' flex flex-col md:flex-row items-center justify-between'>
                        <div className='max-w-[800px] order-2 '>
                        <h1 className='text-3xl md:text-5xl font-bold capitalize'>{industryInfo.title}</h1>
                        <p className='text-lg leading-tight  mt-5'>{industryInfo?.description}</p>
                        <p className=' text-[#606f7b] mt-3'>{industryInfo?.mainText}</p>
                        </div>

                        {industryInfo?.image && <img className='order-1 md:order-2 w-full md:w-[480px] h-[360px] object-contain' src={industryInfo.image} alt={industryInfo.title} />}
                    </div>
                } />
            </div>
        </div>
    )
}

export default IndustryDetails