import React from 'react'
import ai from "../assets/images/ai.png"
import iot from "../assets/images/iot.png"
import eutility from "../assets/images/eutility.png"
import electricengineering from "../assets/images/electricengineering.png"
import industrialautomation from "../assets/images/industrialautomation.png"
import educationIcon from "../assets/images/educationicon.svg"
import semiconductorIcon from "../assets/images/semiconductorIcon.svg"
import ecommereceIcon from "../assets/images/ecommereceIcon.svg"

import rail from "../assets/images/rail.png"
import { Link } from 'react-router-dom'

const industries = [
    { name: "Rail", href: "/industries/rail", icon: rail, description: "Standard-compliant, resilient, and cutting-edge solutions" },
    { name: "E-Commerce", href: "/industries/ecommerce", icon: ecommereceIcon, description: "Transforming your vision into a thriving online marketplace." },
    { name: "Education", href: "/industries/education", icon: educationIcon, description: "Empowering learners through AI-driven innovation." },
    { name: "Semiconductors", href: "/industries/semiconductors", icon: semiconductorIcon, description: "Driving innovation through advanced semiconductor solutions." },
    { name: "IoT", href: "/industries/iot", icon: iot, description: "Connecting the future, one smart solution at a time." },
    { name: "Healthcare & Lifestyle", href: "/industries/healthcarelifestyle", icon: industrialautomation, description: "Bridging Wellness and Technology for a Healthier Tomorrow." },
    { name: "Electric Utility", href: "/industries/electricutility", icon: eutility, description: "Powering the future with innovative utility solutions." },
    { name: "Artificial Intelligence", href: "/industries/ai", icon: ai, description: "Intelligence-driven solutions for digital transformation." },
    { name: "Hardware Engineering", href: "/industries/hardwareengineering", icon: electricengineering, description: "Empowering innovation through precision-engineered hardware solutions." }
];


function IndustriesNavBar() {
    return (
        <div>
           <div className='grid grid-cols-3 gap-8 p-10 relative justify-center'>
                {industries.map((industry, index) => (
                    <Link
                        key={index}
                        to={industry.href}
                        className="flex items-center gap-5 p-2 rounded-1xl group hover:bg-primary  border bg-[#f7f8f9ed]"
                    >
                        <img
                            src={industry.icon}
                            className="object-contain h-10"
                            alt={`${industry.name} Icon`}
                        />
                        <div className='flex flex-col justify-center'>
                            <p className="mt-2 font-JostMedium text-lg font-medium">
                                {industry.name}
                            </p>
                            <p className='text-sm text-[#727579]'>{industry.description}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default IndustriesNavBar;