import { TechCard } from "./TechCard";
import curvedline from "../assets/images/curvedline.png"


export const CategorySection = ({ category }) => (
  <div className="flex flex-col md:flex-row gap-5 md:gap-20 px-5 xl:px-2 mt-10">
    <div className="relative">
      <h2 className="text-2xl md:text-3xl hover:text-neutral flex-none w-[350px]">
        {category.title}
      </h2>
      <img className="absolute w-20 top-15 left-0" src={curvedline} alt="Curved Line" />
    </div>
    <div className="flex gap-6 flex-wrap border-b-[1px] pb-10 flex-1">
      {category.technologies.map((tech, index) => (
        <TechCard key={index} tech={tech} />
      ))}
    </div>
  </div>
);
