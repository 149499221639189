import React from "react";
import sdkcycle from "../assets/images/sofwaredevelopmentprocess.png";
import sdl from "../assets/images/sdl3.png";
import { BiPhoneCall } from "react-icons/bi";


const phases = [
  {
    title: "Analysis",
    description:
      "Project stakeholders and software developers collaborate to gather and document the software requirements",
  },
  {
    title: "System Design",
    description:
      "Software architecture and overall system design are defined, considering factors such as data structures, modules, interfaces, and external dependencies",
  },
  {
    title: "Development",
    description:
      "Developers write the code, following the design specifications and coding standards. This phase involves programming, unit testing, and integration of different software modules",
  },
  {
    title: "Testing",
    description:
      "Testers verify that the software functions as intended and meets the specified requirements. System testing and user acceptance testing are conducted to ensure the quality and reliability of the software",
  },
  {
    title: "Deployment",
    description:
      "Product is installed and configured in the production environment. This involves data migration, user training, and setting up cloud/on-premises infrastructure",
  },
  {
    title: "Maintenance",
    description:
      "It involves addressing issues, bug fixes, and making updates or enhancements to the software based on user feedback and changing requirements",
  },
];


function OurProcess() {
  return (
    <div className="mb-12 mt-10 md:mt-10">
      <div className=" flex  items-center flex-col   ">
        <h1 className=" curved-heading font-bold  text-3xl md:text-4xl text-center">
          Our  Software Development Process
        </h1>

        <div className=" max-w-[64rem] mt-5">
          <p className="  text-[#606f7b]  text-lg text-center">
            We employ an agile software development approach for all types of projects.
          </p>
        </div>
      </div>
      <div class=" shadow-sm  mx-10 md:mx-5 mt-5">
        <img
          src={sdl}
          class="w-full h-96 object-contain  "
          alt="Software Development Icon"
        />
      </div>

      <div className="grid gap-4 grid-cols-1 md:grid-cols-3 px-10 md:px-0 mt-10 md:mt-10">
        {phases.map((phase, index) => (
          <div key={index} className="p-2 hover:bg-primary">
            <h6 className="font-medium text-xl leading-7">{phase.title}</h6>
            <p className="fold:text-base text-base leading-6 laptop:text-base first-letter:capitalize mt-1">
              {phase.description}
            </p>
          </div>
        ))}
      </div>

    </div>
  );
}

export default OurProcess;
